import React from 'react';
import Layout from '../components/Layout';
import ContactSlides from '../components/contactSlideshow';

const Contact = () => (
  <div>
    <Layout>
    <div className={"slideshow"}><ContactSlides /></div>
      <div className={"content"}>
        <ul>
          <li>
            <h1>Contact</h1>
          </li>
        </ul>
      </div>
    </Layout>
  </div>
);

export default Contact; 
